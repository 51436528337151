import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import ImageCell from './ImageCell';

const columns = [
  {
    field: 'image',
    headerName: 'Image',
    width: 160,
    renderCell: (params) => <ImageCell src={params.value} />
  },
  { field: '_id', headerName: 'ID', width: 150, sortable: true, filterable: true },
  { field: 'description', headerName: 'Description', width: 200, filterable: true },
  { field: 'position', headerName: 'Position', width: 130, filterable: true },
  { field: 'severity', headerName: 'Severity', width: 130, filterable: true },
  { field: 'longitude', headerName: 'Longitude', width: 130, filterable: true },
  { field: 'latitude', headerName: 'Latitude', width: 130, filterable: true },
  { field: 'date', headerName: 'Date', width: 180, filterable: true, sortable: true },
  { field: 'vehicleId', headerName: 'Vehicle ID', width: 130, filterable: true },
  { field: 'trailerId', headerName: 'Trailer ID', width: 130, filterable: true },
  { field: 'driverName', headerName: 'Driver Name', width: 130, filterable: true },
  { field: 'userId', headerName: 'User ID', width: 150, filterable: true },
  { field: 'creationDate', headerName: 'Creation Date', width: 180, filterable: true, sortable: true },
  { field: 'lastUpdateDate', headerName: 'Last Update Date', width: 180, filterable: true, sortable: true },
  { field: 'lastUpdateUserId', headerName: 'Last Update User ID', width: 150, filterable: true },
  { field: 'status', headerName: 'Status', width: 120, filterable: true },
  { field: 'duplicateGroup', headerName: 'Duplicate Group', width: 150, filterable: true },
];

const DataGridComponent = ({ rows, loading }) => (
  <div style={{ height: 650, width: '100%', margin: 'auto' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5, 10, 20]}
      pagination
      checkboxSelection
      disableSelectionOnClick
      resizable={true}
      columnReorder={true}
      sortingMode="server"
      filterMode="server"
      components={{ Toolbar: GridToolbar }}
      loading={loading}
      getRowId={(row) => row._id}
    />
  </div>
);

export default DataGridComponent;
