import { useState, useEffect } from 'react';

const useFetchData = (url) => {
  const [data, setData] = useState({ rows: [], loading: true, error: null });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error('Network response was not ok');
        const json = await response.json();
        setData({
          rows: json.map(item => ({
            ...item,
            id: item._id,
            image: item.imageBytes ? item.imageBytes[0] : null
          })),
          loading: false,
          error: null
        });
      } catch (error) {
        setData({ rows: [], loading: false, error: error.message });
      }
    };

    fetchData();
  }, [url]);

  return data;
};

export default useFetchData;
