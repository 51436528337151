import React from 'react';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import DataGridComponent from './components/DataGridComponent';
import useFetchData from './hooks/useFetchData';
import './App.css';

const appTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
        },
      },
    },
  },
});

const App = () => {
  const { rows, loading, error } = useFetchData('http://spedtec.de:8888/damageReports?returnImages=true');

  if (error) {
    return <div style={{ color: 'red', padding: 20, textAlign: 'center' }}>Error: {error}</div>;
  }

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline />
      <DataGridComponent rows={rows} loading={loading} />
    </ThemeProvider>
  );
}

export default App;
